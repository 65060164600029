<template>
  <section id="member_login">
    <div class="sub"
      :style="{backgroundImage: `url(${require('@/assets/img/member/bg_1.jpg')})`}">
      <div class="sub_box">
        <div class="main_title txt-center txt-white">
          <h3 class="eng">new member</h3>
          <h5>
            還沒成為會員？
            <img class="deco_flower" src="@/assets/img/deco_flower_white.svg" alt="">
          </h5>
        </div>

        <router-link to="/Signup" class="btn btn-white">
          立即加入
        </router-link>
      </div>
    </div>
    <div class="main">
      <div class="login_box">
        <div class="main_title txt-center">
          <h3 class="eng">WELCOME NUWA</h3>
          <h5>
            {{$t('memberLogin')}}
            <img class="deco_flower" src="@/assets/img/deco_flower.svg" alt="">
          </h5>
        </div>
        <form id="login_form" name="login_form" class="small">
          <input type="text" name="acc" value="" id="acc"
            :placeholder="this.$t('loginAccountPlaceholder')"
            v-model="formData.account">
          <div class="password_outer login_pswd">
            <!-- <small class="pswd_hint">初診密碼預設為掛號時生日西元年月日(ex.20210101)</small> -->
            <input
              :type="(ifPasswordAbleToSee) ? 'text' : 'password'"
              name="upwd"
              id="upwd"
              :placeholder="this.$t('loginPasswordPlaceholder')"
              v-model="formData.password">
            <img src="@/assets/img/eye.jpg" alt="" class="eye"
              @click="ifPasswordAbleToSee = !ifPasswordAbleToSee">
          </div>
          <div class="remember txt-dark_gray">
            <input type="checkbox" id="save_id" value="Y" v-model="rememberUser">
            <label for="save_id"><span></span>{{$t('rememberMe')}}</label>
            <span class="small">|</span>
            <router-link to="/forgetpassword" class="small">{{$t('fogotPassword')}}</router-link>
          </div>
          <button type="button" class="btn p" id="send_btn" @click="clickLogIn()">
            {{$t('memberLogin')}}
          </button>
        </form>
      </div>
    </div>

    <div id="change_password_lightbox"
      v-show="changePasswordLightboxOpen">
      <div class="cover"></div>
      <div class="box">
        <div class="text txt-center">
          <h5 class="txt-bold">
            {{$t('changePassword')}}
            <img class="deco_flower" src="@/assets/img/deco_flower.svg" alt="">
          </h5>
          <p>{{$t('newPasswordInfo')}}</p>
          <form class="">
            <p class="small">{{$t('newPassword')}}</p>
            <div class="password_outer">
              <input
                :type="(ifPasswordAbleToSee2) ? 'text' : 'password'"
                @blur="passwordTest2"
                v-model="newPassword">
              <img src="@/assets/img/eye.jpg" alt="" class="eye"
                @click="ifPasswordAbleToSee2 = !ifPasswordAbleToSee2">
            </div>
            <p class="small">{{$t('comfirmNewPassword')}}</p>
            <div class="password_outer">
              <input
                :type="(ifPasswordAbleToSee3) ? 'text' : 'password'"
                @blur="passwordTest3"
                v-model="checkNewPassword">
              <img src="@/assets/img/eye.jpg" alt="" class="eye"
                @click="ifPasswordAbleToSee3 = !ifPasswordAbleToSee3">
            </div>
          </form>
          <button type="button"
            class="btn p"
            @click="clickToChangePassword();">
            {{$t('saveMod')}}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import '@/assets/scss/login.scss';

import { mapMutations, mapState } from 'vuex';
import { logIn, changePassword } from '@/lib/public';

export default {
  name: 'Login',
  data() {
    return {
      old_password: '',
      newPassword: '',
      checkNewPassword: '',
      changePasswordLightboxOpen: false,
      // changeEmailLightboxOpen: false,
      ifPasswordAbleToSee2: false,
      ifPasswordAbleToSee3: false,

      rememberUser: false,

      ifPasswordAbleToSee: false,
      formData: {
        account: '',
        password: '',
      },
    };
  },
  created() {
    const rememberInfo = this.$cookies.get('loginInfo');
    console.log('remember info', rememberInfo);
    if (rememberInfo) {
      this.formData = rememberInfo;
      this.rememberUser = true;
    }
  },
  computed: {
    ...mapState([
      'member',
    ]),
  },
  methods: {
    ...mapMutations([
      'logIn',
    ]),
    clickLogIn() {
      const submitData = this.formData;
      if (submitData.account === '' || submitData.account === null) {
        this.$customSWAL({ icon: 'error', title: this.$t('customSWALInputAccount') });
      } else if (submitData.password === '' || submitData.password === null) {
        this.$customSWAL({ icon: 'error', title: this.$t('customSWALInputPassword') });
      } else {
        /** 記住我 */
        if (this.rememberUser) {
          this.$cookies.set('loginInfo', this.formData);
        } else {
          this.$cookies.remove('loginInfo');
        }
        logIn(
          submitData,
          // 成功登入
          (response) => {
            console.log('xxx', response.data.data);
            this.logIn(response.data.data);
            const isPwdChange = response.data.data.member.pwd_changed_at;
            console.log('isPwdChange', isPwdChange);
            if (isPwdChange) {
              this.$router.push('/MemberHome');
            } else {
              this.openLightbox();
            }
          },
          // 登入失敗
          () => {
            this.$customSWAL({ icon: 'error', title: this.$t('loginError') });
          },
        );
      }
    },
    passwordTest2() {
      const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Za-z0-9]{8,}$/;
      if (!passwordRegex.test(this.newPassword)) {
        this.$customSWAL({ icon: 'error', title: this.$t('passwordError') });
        this.newPassword = '';
      }
    },
    passwordTest3() {
      const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Za-z0-9]{8,}$/;
      if (!passwordRegex.test(this.checkNewPassword)) {
        this.$customSWAL({ icon: 'error', title: this.$t('passwordError') });
        this.checkNewPassword = '';
      }
    },
    clickToChangePassword() {
      const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Za-z0-9]{8,}$/;
      if (this.newPassword !== this.checkNewPassword) {
        this.$customSWAL({ icon: 'error', title: this.$t('passwordComfirmError') });
      } else if (!passwordRegex.test(this.newPassword) || !passwordRegex.test(this.checkNewPassword)) {
        this.$customSWAL({ icon: 'error', title: this.$t('passwordError') });
      } else {
        changePassword(
          this.$cookies.get('api_token'),
          {
            old_password: this.member.birthday.replace(/-/g, ''),
            password: this.newPassword,
          },
          () => {
            this.$customSWAL({
              title: this.$t('modifySuccess'),
              thenFunc: () => {
                this.closeLightbox();
                this.$router.push('/MemberHome');
              },
            });
          },
          (message) => {
            this.$customSWAL({ icon: 'error', title: message });
          },
        );
      }
    },
    openLightbox() {
      this.changePasswordLightboxOpen = true;
      const el = document.getElementsByTagName('body')[0];
      el.classList.add('lock');
    },
    closeLightbox() {
      this.changePasswordLightboxOpen = false;
      const el = document.getElementsByTagName('body')[0];
      el.classList.remove('lock');
    },
  },
};
</script>
